<template>
  <div class="warp">
    <template v-if="!noData">
      <div class="message" @click="toDetail(item.id, item.messageId)" v-for="(item, index) in messageList" :key="index">
        <div class="title">
          <div v-if="item.readStatus == 2" class="dot" ></div>
          <span :class="item.readStatus == 2?'noneP':''">{{ item.msgTitle }}</span>
        </div>
        <div class="miniTitle" v-html="item.msgContent"></div>
        <div class="time">{{ timestampReturnDate(item.createTime, 'datetime') }}</div>
      </div>
    </template>
    <div class="noData" v-if="noData">
      <img src="@/assets/img/noData.png" alt="" srcset="">
      <span>暂无信息</span>
    </div>
  </div>
</template>

<script>
import way from '../../api/encapsulation'
export default {
  name: 'messageCenter',
  data () {
    return {
      studentId: '',
      noData: false,
      messageList: []
    }
  },
  watch: {
    '$store.state.isGetToken' (val) {
      // alert('valW:' + val)
      let type = this.$store.state.deviceType
      if (val && type === 1) {
        // ios
        // alert('valwatch:' + val)
        window.webkit.messageHandlers.getData.postMessage('studentId')
        window.getIosStudentId = this.getIosStudentId
      } else if (val && type === 2) {
        // 安卓
        this.getAnStudentId()
      } else {
        console.log('本地测试')
        // this.studentId = 24640
        this.getList()
      }
    }
  },
  created () {
    // console.log(sessionStorage.getItem('token'))
    let val = sessionStorage.getItem('token')
    // alert('val:' + val)
    let type = this.$store.state.deviceType
    if (val && type === 1) {
      // ios
      window.webkit.messageHandlers.getData.postMessage('studentId')
      window.getIosStudentId = this.getIosStudentId
    } else if (val && type === 2) {
      // 安卓
      this.getAnStudentId()
    } else if (val) {
      console.log('本地测试')
      this.studentId = 349193
      // 24640
      this.getList()
    }
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    // 安卓获取学生id
    getAnStudentId () {
      this.studentId = window.Android.getStudentId().toString()
      // 并获取列表
      this.getList()
    },
    // ios获取学生id
    getIosStudentId (studentId) {
      // alert('Ios:' + this.studentId)
      // alert('getStudentId:' + studentId.toString())
      this.studentId = studentId.toString()
      // 并获取列表
      this.getList()
    },
    // 获取新闻列表
    getList () {
      // alert('getList: ' + this.studentId)
      this.$request.getMessageList().then((res) => {
        console.log(res)
        if (res.data.code == 0) {
          if (res.data.data.length > 0) {
            this.messageList = res.data.data
            // alert('getList2: ' + this.messageList)
          } else {
            this.noData = true
          }
        }
      }).catch((err) => {
        // alert('err:' + err)
        console.log(err)
      });
    },
    toDetail (id, messageId) {
      console.log(id)
      this.$router.push({
        name: 'messageDetail',
        query: {
          id: id,
          messageId: messageId
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.warp {
  background: #F9F9F9;
  height: inherit;
  overflow-y: overlay;
  padding-top: 20px;
  padding-bottom: 40px;
  .message:last-child{
    margin-bottom: 50px;
  }
  .message {
    margin: 0 20px;
    background: #fff;
    padding: 16px 23px 16px 9px;
    font-size: 17px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363840;
    line-height: 22px;
    border-radius: 4px;
    margin-bottom: 16px;
    .title {
      // display: flex;
      // align-items: center;
      margin-bottom: 8px;
      .dot {
        width: 8px;
        height: 8px;
        background: #E21A1A;
        border-radius: 50%;
        float: left;
        margin-right: 7px;
        margin-top: 7px;
      }

      span.noneP {
        padding-left: 0px !important;
      }
      span {
        padding-left:14px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        width: auto;
        word-break: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
    }

    .miniTitle {
      margin-left: 14px;
      font-size: 14px;
      color: #666666;
      line-height: 21px;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      width: auto;
      word-break: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    .time {
      margin-left: 14px;
      font-size: 14px;
      color: #999999;
      line-height: 14px;
    }
  }
  .noData{
    display: flex;
    align-items: center;
    flex-flow: column;
    margin-top: 50%;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363840;
    line-height: 16px;
    img {
      width: 70%;
    }
  }
}
</style>